<template>
  <b-navbar toggleable="lg" type="dark" variant="white">
    <b-navbar-brand to="/" class="mx-auto">
      <img src="@/assets/logo.svg" alt="Blogger" />
      <span class="sr-only"> Blogger</span>
    </b-navbar-brand>
  </b-navbar>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
